import { useMainStore } from './stores/mainStore';

export function getCookie(name: string) {
  var dc = document.cookie;
  var prefix = name + '=';
  var begin = dc.indexOf('; ' + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
  }
  var end = document.cookie.indexOf(';', begin);
  if (end == -1) {
    end = dc.length;
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; Domain=lewdspace.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getTokenFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    localStorage.setItem('token', token);
    // remove token from url
    window.history.replaceState({}, document.title, window.location.pathname);
    useMainStore.getState().setUserValues(getTokenValues());
  }
};

export const getImageSrc = (imagePath: string | undefined): string => {
  if (!imagePath) return '';
  if (imagePath.includes('[')) {
    const startIndex = imagePath.indexOf('[');
    const endIndex = imagePath.indexOf(']');
    const pathWithoutBrackets = imagePath.slice(startIndex + 1, endIndex);
    return `/public/${pathWithoutBrackets}`;
  }
  const pathWithoutBrackets = imagePath.replace(/[\[\]]/g, '');
  const pathWithoutExtras = pathWithoutBrackets.split('.jpg')[0] + '.jpg';
  return `/public/${pathWithoutExtras}`;
};

const showErrorModal = (
  message = 'An error occurred. Would you like to reload the page?',
  cause?: string
) => {
  useMainStore.getState().setError(message, cause);
};

export const fetchData = async (url: string) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  const { token } = getTokenValues();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      showErrorModal(data.error, data.cause);
      setTimeout(() => {
        useMainStore.setState({ typing: false });
      }, 2000);
    } else {
      return data;
    }
  } catch (error: any) {
    console.error('Fetch error:', error);
    showErrorModal();
    throw error;
  }
};

export const postData = async (url: string, body: any) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  const { token } = getTokenValues();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      showErrorModal(data.error, data.cause);
      useMainStore.setState({ typing: false });
    } else {
      return data;
    }
  } catch (error: any) {
    console.error('Post error:', error);
    showErrorModal();
    throw error;
  }
};

function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function getTags(token?: string) {
  const localToken = localStorage.getItem('token');
  const tokenToUse = token || localToken;
  if (tokenToUse) {
    const tokenData = JSON.parse(atob(tokenToUse.split('.')[1]));
    const tags = tokenData?.tags;
    return tags;
  }
  return [];
}

export function getTokenValues() {
  const token = localStorage.getItem('token');
  if (token) {
    const { stamp, tier, user, tags } = parseJwt(token);
    if (parseInt(stamp) < Date.now()) {
      return { user, tier: 0, token: undefined, tags: [] };
    } else {
      return { token, tier, user, tags: tags || [] };
    }
  }
  return { token: undefined, tier: 0, user: undefined };
}

const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

export const arrayUnique = (array: any[]) => {
  return array.filter(onlyUnique);
};

export const randomTimeout = (
  min: number,
  max: number,
  callback?: () => void
) => {
  return new Promise<void>((resolve) =>
    setTimeout(
      () => {
        callback && callback();
        resolve();
      },
      Math.floor(Math.random() * (max - min + 1)) + min
    )
  );
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IItem } from '../stores/mainStore';
import { useMainStore } from '../stores/mainStore';
import { getImageSrc } from '../utils';
// @ts-ignore
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

interface FeedItemProps {
  item: IItem;
  character?: IItem | undefined;
}

export const FeedItem: React.FC<FeedItemProps> = ({ item, character }) => {
  const likePost = useMainStore((state) => state.likePost);
  const [isLikeAnimating, setIsLikeAnimating] = useState(false);

  const handleLikeClick = () => {
    likePost(item._id);
    setIsLikeAnimating(true);
    setTimeout(() => setIsLikeAnimating(false), 300); // Duration of animation
  };

  const images = item.images?.split('|').filter(Boolean) || [];

  return (
    <div className="feed-item-wrapper mb-6">
      <div
        className="story-ring1 flex justify-center items-center
              relative cursor-pointer
              transition-all duration-150 delay-100"
      >
        {images.length > 1 ? (
          <Splide
            options={{
              type: 'loop',
              perPage: 1,
              arrows: false,
              pagination: true,
            }}
            className="w-full rounded-3xl"
          >
            {images.map((image, index) => (
              <SplideSlide key={index}>
                <img
                  className="h-full w-full rounded-3xl"
                  src={getImageSrc(image)}
                  alt={`${item.name} - ${index + 1}`}
                />
              </SplideSlide>
            ))}
          </Splide>
        ) : (
          <img
            className="h-full w-full rounded-3xl"
            src={getImageSrc(images[0])}
            alt={item.name}
          />
        )}

        {character && (
          <div className="left-4 top-4 absolute backdrop-blur-lg rounded-xl border border-gray-300">
            <Link
              to={`/app/profile/${character?._id}`}
              className="flex p-1.5 px-3"
            >
              <img
                src={getImageSrc(character?.images)}
                className="mr-2 w-8 h-8 rounded-full border border-gray-400"
                alt={character?.name}
              />

              <div className="text-white-600 font-bold text-sm text-gray-50 hover:underline mt-1">
                {character?.name || 'Unknown'}
              </div>
            </Link>
          </div>
        )}

        {/* <FeedItemActions item={item} onLikeClick={handleLikeClick} /> */}
      </div>

      <div className="mt-2 py-0 px-4">
        <div className="text-gray-900 text-sm flex">
          <button
            onClick={handleLikeClick}
            className={`flex-shrink-0 flex items-center bg-transparent border-none cursor-pointer p-0 mr-2 transition-transform duration-300 ${isLikeAnimating ? 'scale-125' : ''}`}
            aria-label="Like"
          >
            <i
              className={`mdi ${
                item.likedBy?.length
                  ? 'mdi-heart text-red-500'
                  : item.likes
                    ? 'mdi-heart-outline text-red-500'
                    : 'mdi-heart text-gray-500'
              } mdi-24px mr-1`}
            ></i>
            {!!item.likes && <span>{item.likes}</span>}
          </button>
          {item.description && (
            <p className="flex-grow">
              {character ? (
                <Link
                  to={`/app/profile/${character?._id}`}
                  className="font-bold hover:underline"
                >
                  {character?.name}
                </Link>
              ) : (
                <span className="font-bold">{item.name}</span>
              )}
              : {item.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

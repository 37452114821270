import { create, StateCreator } from 'zustand';
import {
  fetchData,
  getTags,
  getTokenFromUrl,
  getTokenValues,
  postData,
  randomTimeout,
} from '../utils';

export interface IItem {
  _id: string;
  name?: string;
  images?: string;
  backgroundImage?: string;
  active?: boolean;
  description?: string;
  tags?: string[];
  score?: number;
  charId?: string;
  likes?: number;
  likedBy?: string[];
}

export interface CharacterSlice {
  characters: IItem[];
  characterPosts: { [key: string]: IItem[] };
  activeCharacter?: IItem;
  me?: IItem;
  getMePromise?: Promise<void>;
  getCharacters: () => Promise<void>;
  getCharacter: (id: string) => Promise<IItem | undefined>;
  getMe: () => Promise<void>;
  getCharacterPosts: (id: string, pageSize?: number) => void;
  followCharacter: (id: string) => Promise<void>;
  setActiveCharacter: (charId: string) => void;
}

export interface InboxMessage {
  name: string;
  _id: string;
  message: string;
}

export interface UserSlice extends UserValues {
  setUserValues: (values: UserValues) => void;
  initUser: () => void;
  logout: () => void;
}

export interface UserValues {
  token?: string;
  tier?: number;
  user?: string;
  tags?: string[];
}

export interface ChatSlice {
  messages: { response: string; role: string }[];
  clearMessages: () => void;
  horny: number;
  options: string[];
  locationSelect: boolean;
  actionSelect: boolean;
  timeout?: number;
  typing: boolean;
  inbox: InboxMessage[];
  stopTyping: () => void;
  getInbox: () => Promise<void>;
  sendChatMessage: (
    id: string,
    gameType?: string,
    message?: string
  ) => Promise<any>;
}

export interface ErrorSlice {
  error: string;
  cause: string;
  clearError: () => void;
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set
) => ({
  initUser: () => {
    const { tier, token, user, tags } = getTokenValues();
    set({ tier, token, user, tags });
  },
  setUserValues: (values: UserValues) => {
    set(values);
  },
  logout: () => {
    localStorage.removeItem('token');
    window.location.reload();
    set({ tier: undefined, token: undefined, user: undefined });
  },
});

export const createCharacterSlice: StateCreator<
  CharacterSlice,
  [],
  [],
  CharacterSlice
> = (set, get) => ({
  characters: [],
  characterPosts: {},
  activeCharacter: undefined,
  me: undefined,
  getMe: async () => {
    if (get().getMePromise) {
      return get().getMePromise;
    }
    getTokenFromUrl();
    const getMePromise = fetchData('/api/character/get/me');
    set({ getMePromise });
    const me = await getMePromise;
    if (me && me.token) {
      localStorage.setItem('token', me.token);
    }
    set({ me });
  },
  getMePromise: undefined,
  getCharacters: async () => {
    if (!get().me) {
      await get().getMe();
    }
    const characters = await fetchData('/api/character/all');
    set((_) => ({ characters }));
  },
  getCharacter: async (id: string) => {
    if (get().characters.find((char) => char._id === id && id !== 'me')) {
      return get().characters.find((char) => char._id === id);
    }
    const character = await fetchData(`/api/character/get/${id}`);
    set({
      activeCharacter: character,
      characters: [
        ...get().characters.filter((char) => char._id !== id),
        character,
      ],
    });
    return character;
  },
  getCharacterPosts: async (id: string, pageSize = 20) => {
    const existingPosts = get().characterPosts[id] || [];
    const currentPage = Math.floor(existingPosts.length / pageSize) + 1;

    // If the last page wasn't full, we've reached the end
    if (
      id !== 'me' &&
      existingPosts.length > 0 &&
      existingPosts.length % pageSize !== 0
    ) {
      return;
    }

    const posts = await fetchData(
      `/api/character/posts/${id}?page=${currentPage}&pageSize=${pageSize}`
    );
    const existingPostIds = existingPosts.map((post) => post._id);
    const newPosts = posts.filter(
      (post: IItem) => !existingPostIds.includes(post._id)
    );

    set((state) => ({
      characterPosts: {
        ...state.characterPosts,
        [id]: [
          ...(state.characterPosts[id] || []),
          ...(newPosts.length
            ? newPosts
            : !posts.length
              ? [{ _id: null }]
              : []),
        ],
      },
    }));
  },
  followCharacter: async (id: string) => {
    const character = await fetchData(`/api/character/follow/${id}`);
    set((state) => ({
      characters: state.characters.map((char) =>
        char._id === id ? { ...char, active: character.active } : char
      ),
      activeCharacter:
        state.activeCharacter?._id === id
          ? { ...state.activeCharacter, active: character.active }
          : state.activeCharacter,
    }));
    return character;
  },
  setActiveCharacter: async (charId: string) => {
    set({ activeCharacter: await get().getCharacter(charId) });
  },
});

export const createChatSlice: StateCreator<ChatSlice, [], [], ChatSlice> = (
  set,
  get
) => ({
  typing: false,
  messages: [],
  clearMessages: () => {
    set({ messages: [], timeout: undefined, typing: false });
  },
  stopTyping: () => {
    set({ typing: false });
  },
  horny: 0,
  options: [],
  locationSelect: false,
  actionSelect: false,
  inbox: [],
  getInbox: async () => {
    const inbox = await fetchData('/api/message/inbox');
    set({ inbox });
  },
  sendChatMessage: async (id: string, gameType = 'chat', message = '') => {
    if (get().messages.length > 0) {
      randomTimeout(500, 1500, () => {
        set(() => ({
          typing: true,
        }));
      });
    }
    // Add user message immediately if it exists
    if (message && !get().actionSelect) {
      set(() => ({
        messages: [...get().messages, { response: message, role: 'user' }],
      }));
    }
    set(() => ({ options: [] }));

    const response = await postData(`/api/message/game/${id}`, {
      message,
      gameType,
    });
    const existingMessages = get().messages;
    if (!existingMessages.length) {
      set(() => ({
        messages: response.messages,
        horny: response.horny,
        options: response.options,
        locationSelect: !!response.locationSelect,
        actionSelect: !!response.actionSelect,
        timeout: response.timeout,
      }));
      return;
    }
    const newMessages = response.messages.filter(
      (msg: any) =>
        !existingMessages.find(
          (existing) =>
            existing.response.trim().slice(0, 300) ===
            msg.response.trim().slice(0, 300)
        )
    );
    set(() => ({ typing: false }));
    // Add first message immediately
    if (newMessages.length > 0) {
      set((state) => ({
        messages: [...state.messages, newMessages[0]],
        horny: response.horny,
        locationSelect: !!response.locationSelect,
      }));
    }

    // Process remaining messages with delays
    if (newMessages.length > 1) {
      for (let i = 1; i < newMessages.length; i++) {
        const msg = newMessages[i];
        if (msg.role === 'character') {
          set(() => ({ typing: true }));
        }
        if (!msg.response.includes('.jpg')) {
          await randomTimeout(700, 700 + msg.response.length * 50);
        } else {
          await randomTimeout(500, 800);
        }

        set((state) => ({
          messages: [...state.messages, msg],
          typing: false,
        }));
        if (i === newMessages.length - 1) {
          set(() => ({
            timeout: response.timeout,
            options: response.options,
            actionSelect: !!response.actionSelect,
          }));
        }
      }
    } else {
      set(() => ({
        timeout: response.timeout,
        options: response.options,
        actionSelect: !!response.actionSelect,
      }));
    }
    return response;
  },
});

export const createErrorSlice: StateCreator<ErrorSlice, [], [], ErrorSlice> = (
  set
) => ({
  error: '',
  cause: '',
  clearError: () => set({ error: '', cause: '' }),
});

export type MainStore = CharacterSlice &
  UserSlice &
  ChatSlice &
  ErrorSlice & {
    likePost: (id: string) => Promise<void>;
    restartProgress: () => Promise<void>;
    restartGame: (id: string, gameType: string) => Promise<void>;
    sendMessage: (
      id: string,
      gameType?: string,
      message?: string
    ) => Promise<any>;
    feed: IItem[];
    updateFeed: (page?: number, pageSize?: number) => Promise<void>;
    reloadFeed: (page?: number, pageSize?: number) => Promise<void>;
    setError: (message: string, cause?: string) => void;
    setTag: (tag: string, value: boolean) => Promise<void>;
  };
export const useMainStore = create<MainStore>()((...a) => {
  const [set, get] = a;
  return {
    ...createCharacterSlice(...a),
    ...createChatSlice(...a),
    ...createUserSlice(...a),
    ...createErrorSlice(...a),
    feed: [],
    setError: (message: string, cause?: string) => {
      set({ error: message, cause });
      get().stopTyping();
      if (cause === 'tier') {
        get().clearMessages();
        get().sendMessage(get().activeCharacter?._id || '');
      }
    },
    reloadFeed: async (page = 1, pageSize = 20) => {
      if (!get().me) {
        await get().getMe();
      }
      const feedItems: IItem[] = await fetchData(
        `/api/feed?page=${page}&pageSize=${pageSize}`
      );
      set((_) => ({
        feed: feedItems,
      }));
    },
    updateFeed: async (page = 1, pageSize = 20) => {
      if (!get().me) {
        await get().getMe();
      }
      const feedItems: IItem[] = await fetchData(
        `/api/feed?page=${page}&pageSize=${pageSize}`
      );
      set((_) => ({
        feed: get()
          .feed.filter((item) => !feedItems.find(({ _id }) => _id === item._id))
          .concat(feedItems),
      }));
    },
    restartProgress: async () => {
      await fetchData(`/api/user/restart`);
      window.location.reload();
    },
    restartGame: async (id: string, gameType: string) => {
      set(() => ({
        messages: [],
        options: [],
        locationSelect: false,
        actionSelect: false,
        timeout: undefined,
      }));
      const response = await fetchData(
        `/api/message/restart/${id}/${gameType}`
      );
      set(() => ({
        messages: response.messages,
        options: response.options,
        locationSelect: !!response.locationSelect,
        actionSelect: !!response.actionSelect,
        timeout: response.timeout,
      }));
    },
    likePost: async (id: string) => {
      const response = await fetchData(`/api/feed/like/${id}`);
      const { likes, likedBy } = response;
      set((state) => ({
        feed: state.feed.map((item) =>
          item._id === id ? { ...item, likes, likedBy } : item
        ),
        characterPosts: {
          ...state.characterPosts,
          [state.activeCharacter?._id || '']: state.characterPosts[
            state.activeCharacter?._id || ''
          ]?.map((item) =>
            item._id === id ? { ...item, likes, likedBy } : item
          ),
        },
      }));
    },
    sendMessage: async (id: string, gameType = 'chat', message = '') => {
      // if (message && !get().options.includes(message)) {
      //   return;
      // }
      const response = await get().sendChatMessage(id, gameType, message);
      if (gameType === 'chat') {
        set(() => ({
          characters: get().characters.map((char) =>
            char._id === 'me'
              ? { ...char, score: response.score || char.score }
              : char
          ),
          timeout: response.timeout,
        }));
        get().getCharacterPosts('me');
      }
    },
    setTag: async (tag: string, value: boolean) => {
      const data = await postData('/api/user/setTag', { tag, value });
      if (data && data.token) {
        const tags = getTags(data.token);
        localStorage.setItem('tags', JSON.stringify(tags));
        localStorage.setItem('token', data.token);
        get().initUser();
      }
      get().reloadFeed(1);
      get().characterPosts = {};
    },
  };
});

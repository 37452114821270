import { Outlet } from 'react-router-dom';

export const GameLayout = () => {
  return (
    <div className="my-auto" style={{ height: 'min-content' }}>
      <div
        style={{ height: '100dvh' }}
        className="overflow-hidden flex items-center justify-center from-yellow-50 via-teal-300 to-yellow-50 bg-gradient-to-br"
      >
        <div style={{ height: '100dvh' }} className="flex flex-col">
          <div
            style={{ height: '100dvh', paddingTop: '10px' }}
            className="w-screen md:w-[450px] px-7 py-7 mx-auto bg-white rounded-2xl shadow-xl"
          >
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col relative">
                {/* <CosplayHeader /> */}
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

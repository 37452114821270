import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AppLayout } from './components/AppLayout';
import { Feed } from './components/Feed';
import { Profile } from './components/Profile';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Chat } from './components/Chat';
import { Inbox } from './components/Inbox';
import { Explore } from './components/Explore';
import { ErrorModal } from './components/ErrorModal';
import {
  Button,
  Typography,
  FormControlLabel,
  FormControl,
  Switch,
} from '@mui/material';
import { Terms } from './components/Terms';
import { Privacy } from './components/Privacy';
import { useMainStore } from './stores/mainStore';
import { Cosplay } from './components/Cosplay';
import { GameLayout } from './components/GameLayout';
import { getTags } from './utils';
// Create a theme instance
const theme = createTheme({
  // You can customize the theme here
});

const AppContent: React.FC = () => {
  const [showTosModal, setShowTosModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const tags = useMainStore((state) => state.tags);
  const setTag = useMainStore((state) => state.setTag);
  const initUser = useMainStore((state) => state.initUser);
  const [futasSelected, setFutasSelected] = useState(false);

  useEffect(() => {
    // Check TOS agreement on component mount
    const tosAgreed = localStorage.getItem('tosAgree') === 'true';
    if (!tosAgreed) {
      setShowTosModal(true);
    }
    initUser();
  }, []);

  useEffect(() => {
    const tags = getTags();
    let localTags = [];
    try {
      localTags = JSON.parse(localStorage.getItem('tags') || '[]');
    } catch (e) {
      console.error(e);
    }
    if (
      !showTosModal &&
      tags &&
      !tags.includes('futas-set') &&
      !localTags.includes('futas-set')
    ) {
      setShowVisibilityModal(true);
    } else {
      setShowVisibilityModal(false);
    }
  }, [tags, showTosModal]);

  const handleTosAgree = () => {
    localStorage.setItem('tosAgree', 'true');
    setShowTosModal(false);
  };

  const handleVisibilityOk = () => {
    setTag('futas', futasSelected);
  };

  return (
    <>
      <Routes>
        <Route path="/game" element={<GameLayout />}>
          <Route path="cosplay/:charId" element={<Cosplay />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
        </Route>
        <Route path="/app" element={<AppLayout />}>
          <Route path="home" element={<Feed show="all" />} />
          <Route path="feed" element={<Feed show="all" />} />
          <Route path="explore" element={<Explore />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="chat/:charId" element={<Chat />} />
        </Route>
        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
      <ErrorModal />
      {showTosModal &&
        window.location.pathname !== '/terms' &&
        window.location.pathname !== '/privacy' && (
          <div className="absolute top-0 left-0 flex justify-center items-center w-screen h-screen bg-white/80 backdrop-blur-md z-50 p-8 box-border text-center">
            <div className="max-w-[480px] container" id="container">
              <img
                src="/public/adult-sign-icon.svg"
                alt="18+"
                className="w-24 mx-auto mb-4"
              />
              <Button
                variant="outlined"
                onClick={handleTosAgree}
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  mb: 2,
                  borderRadius: '25px',
                  fontSize: '1.2rem',
                  padding: '8px 30px',
                }}
              >
                Continue
              </Button>
              <Typography
                variant="body1"
                sx={{ fontSize: '1.2rem' }}
                className="text-gray-900"
              >
                This website contains age-restricted materials. Please leave the
                page immediately if you're a minor. By continuing you hereby
                agree to comply with all the{' '}
                <b>
                  <a href="/terms">TERMS AND CONDITIONS</a>
                </b>
                .
              </Typography>
            </div>
          </div>
        )}
      {window.location.pathname !== '/terms' &&
        window.location.pathname !== '/privacy' &&
        window.location.pathname !== '/game/cosplay/676f8d1f0753b4199c3aed10' &&
        showVisibilityModal && (
          <div className="absolute top-0 left-0 flex justify-center items-center w-screen h-screen bg-white/80 backdrop-blur-md z-50 p-8 box-border text-center">
            <div
              className="max-w-[480px] container flex flex-col items-center"
              id="container"
            >
              <Typography variant="h5" component="h1" gutterBottom>
                Visibility Settings
              </Typography>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={futasSelected}
                      onChange={(e) => setFutasSelected(e.target.checked)}
                      name="futas"
                      color="primary"
                    />
                  }
                  label="Futas"
                />
              </FormControl>
              <div className="mt-4">
                <Button
                  variant="outlined"
                  onClick={handleVisibilityOk}
                  sx={{
                    borderColor: 'black',
                    color: 'black',
                    borderRadius: '25px',
                    fontSize: '1.2rem',
                    padding: '8px 20px',
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};

import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import {
  Typography,
  Grid,
  Avatar,
  Badge,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Switch,
  Paper,
} from '@mui/material';
import { getImageSrc } from '../utils';
import { useShallow } from 'zustand/shallow';
import { styled } from '@mui/material/styles';
import { FeedItem } from './FeedItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

const BackgroundImage = styled('div')<{ image: string }>(({ image }) => ({
  height: '200px',
  backgroundImage: `url(${getImageSrc(image)})`,
  backgroundSize: 'cover',
  position: 'relative',
}));

const ProfileSection = styled('div')({
  position: 'relative',
  marginTop: '-75px',
  textAlign: 'center',
});

const AvatarWrapper = styled('div')({
  position: 'relative',
  width: 150,
  height: 150,
  margin: '0 auto',
});

const StyledAvatar = styled(Avatar)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: -5,
  left: -5,
  zIndex: 0,
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round',
  },
});

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    background: 'linear-gradient(to top, #fde047, #f97316)',
    color: 'white',
    fontSize: '1.2rem',
    height: '80px',
    minWidth: '80px',
    padding: '0',
    transform: 'translate(50%, 50%)',
    clipPath:
      'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', // Star shape
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const getCharacterPosts = useMainStore((state) => state.getCharacterPosts);
  const posts = useMainStore(
    useShallow((state) => state.characterPosts[id || ''] || [])
  );
  const activeCharacter = useMainStore((state) => state.activeCharacter);
  const setActiveCharacter = useMainStore((state) => state.setActiveCharacter);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const followCharacter = useMainStore((state) => state.followCharacter);
  const restartProgress = useMainStore((state) => state.restartProgress);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const setTag = useMainStore((state) => state.setTag);
  const tags = useMainStore((state) => state.tags);
  const [futasSelected, setFutasSelected] = useState(
    tags?.includes('futas') || false
  );
  // const [boysSelected, setBoysSelected] = useState(
  //   tags?.includes('boys') || false
  // );
  // const [furrySelected, setFurrySelected] = useState(
  //   tags?.includes('furry') || false
  // );

  const level = activeCharacter?.score
    ? Math.floor(activeCharacter.score / 30) + 1
    : 1;
  let progress = activeCharacter?.score
    ? ((activeCharacter.score % 30) / 30) * 100
    : 0;
  if (progress === 0) {
    progress = 100;
  }

  useEffect(() => {
    if (tags?.includes('futas')) {
      setFutasSelected(true);
    } else {
      setFutasSelected(false);
    }
  }, [tags]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await setActiveCharacter(id);
        await getCharacterPosts(id);
      }
    };
    fetchData();
  }, [id]);

  const loadPosts = async () => {
    if (id && !isLoading) {
      setIsLoading(true);
      await getCharacterPosts(id);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        loadPosts();
      }
    }
  };

  const handleFollow = async () => {
    if (id) {
      await followCharacter(id);
    }
  };

  const handleFutasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFutasSelected(event.target.checked);
    setTag('futas', event.target.checked);
  };

  // const handleBoysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setBoysSelected(event.target.checked);
  //   setTag('boys', event.target.checked);
  // };

  // const handleFurryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFurrySelected(event.target.checked);
  //   setTag('furry', event.target.checked);
  // };

  if (!activeCharacter) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div className="app-container1 overflow-y-auto">
      <div className="flex flex-col">
        {id === 'me' && (
          <Paper
            elevation={0}
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: 0,
            }}
          >
            <div className="flex items-start gap-4">
              <VisibilityIcon sx={{ color: 'text.secondary', mt: 0 }} />
              <div className="flex gap-8">
                <div className="flex flex-col items-center">
                  <Typography variant="body1" sx={{ mb: -0.5 }}>
                    Futas
                  </Typography>
                  <Switch
                    checked={futasSelected}
                    onChange={handleFutasChange}
                    name="futas"
                    color="primary"
                  />
                </div>
                {/* <div className="flex flex-col items-center">
                  <Typography variant="body1" sx={{ mb: -0.5 }}>
                    Boys
                  </Typography>
                  <Switch
                    checked={boysSelected}
                    onChange={handleBoysChange}
                    name="boys"
                    color="primary"
                  />
                </div> */}
                {/* <div className="flex flex-col items-center">
                  <Typography variant="body1" sx={{ mb: -0.5 }}>
                    Furry
                  </Typography>
                  <Switch
                    checked={furrySelected}
                    onChange={handleFurryChange}
                    name="furry"
                    color="primary"
                  />
                </div> */}
              </div>
            </div>
          </Paper>
        )}
        <BackgroundImage image={activeCharacter.backgroundImage || ''}>
          <div className="flex justify-between p-4">
            <button onClick={() => navigate(-1)} className="text-white">
              <i className="mdi mdi-arrow-left mdi-24px"></i>
            </button>
            <button className="text-white">
              <i className="mdi mdi-dots-vertical mdi-24px"></i>
            </button>
          </div>
        </BackgroundImage>

        <ProfileSection>
          <StyledBadge
            badgeContent={level}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <AvatarWrapper>
              <StyledCircularProgress
                variant="determinate"
                value={100} // You can make this dynamic based on your needs
                size={160}
                thickness={5}
                sx={{
                  color: '#fae6c0', // Or use your gradient colors
                }}
              />
              <StyledCircularProgress
                variant="determinate"
                value={progress}
                size={160}
                thickness={5}
                sx={{
                  color: '#ffa500',
                  transform: 'rotate(45deg)',
                }}
              />
              <StyledAvatar
                src={getImageSrc(activeCharacter.images)}
                alt={activeCharacter.name}
              />
            </AvatarWrapper>
          </StyledBadge>
          <div className="mt-4 flex items-center justify-center">
            <Typography variant="h5" component="div">
              {activeCharacter.name}
            </Typography>
          </div>
          <Typography
            variant="body1"
            color="text.secondary"
            className="pt-4 text-left px-4"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: isExpanded ? 'none' : 7,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {activeCharacter.description
              ?.split('\n')
              .map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
          </Typography>
          {activeCharacter.description &&
            activeCharacter.description.split('').length > 100 && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-pink-500 hover:text-pink-600 mt-2 block px-4 italic"
              >
                {isExpanded ? 'Show Less' : 'Show More'}
              </button>
            )}
          {id === 'me' && (
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={restartProgress}
                className="px-6 py-2 border border-pink-500 text-pink-500 rounded-full"
              >
                Restart progress
              </button>
              <button
                onClick={() => {
                  const token = localStorage.getItem('token');
                  const user = token && JSON.parse(atob(token.split('.')[1]));
                  setUserId(user?.user || 'No ID found');
                  setOpenModal(true);
                }}
                className="px-6 py-2 border border-green-500 text-green-500 rounded-full"
              >
                Show ID
              </button>
            </div>
          )}
          {id !== 'me' && (
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={handleFollow}
                className={`px-6 py-2 rounded-full ${
                  activeCharacter.active
                    ? 'border border-pink-500 text-pink-500'
                    : 'bg-pink-500 text-white'
                }`}
              >
                {activeCharacter.active ? 'Unfollow' : 'Follow'}
              </button>
              <Link
                to={`/app/chat/${id}`}
                className="px-6 py-2 border border-pink-500 text-pink-500 rounded-full"
              >
                Message
              </Link>
            </div>
          )}
        </ProfileSection>

        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-auto pt-6"
        >
          <div className="px-4 py-8">
            <Grid container spacing={3}>
              {posts
                .filter((post) => post._id)
                .map((post) => (
                  <FeedItem key={post._id} item={post} />
                ))}
            </Grid>
            {isLoading && (
              <Typography align="center" className="mt-4">
                Loading more posts...
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          User ID
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="flex items-center gap-2">
            <Typography>{userId}</Typography>
            <Tooltip
              title="ID copied!"
              arrow
              placement="top"
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
            >
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(userId);
                  setShowTooltip(true);
                  setTimeout(() => setShowTooltip(false), 1500);
                }}
                size="small"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
